// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../stylesheets/application.scss';
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels"
import $ from 'jquery';
import "bootstrap";
// This file will contain your custom CSS
require('admin-lte');
import "@fortawesome/fontawesome-free/js/all";
import './panel/application.js';
import 'jquery-mask-plugin'

$(() => {
  $('[data-toggle="tooltip"]').tooltip()
});

Rails.start()
ActiveStorage.start()
