document.addEventListener('DOMContentLoaded', () => {
  const elements = {
    rgRadio: document.getElementById('document_rg'),
    cnhRadio: document.getElementById('document_cnh'),
    rgFields: document.getElementById('rg_fields'),
    cnhFields: document.getElementById('cnh_fields'),
    rgFront: document.querySelector('[name="panel_hi_bank[representative_document_front]"]'),
    rgBack: document.querySelector('[name="panel_hi_bank[representative_document_back]"]'),
    cnhField: document.querySelector('[name="panel_hi_bank[representative_document_cnh]"]'),
    fetchCNPJBtn: document.getElementById('fetchCNPJBtn'),
    form: document.querySelector('form'),
    cnpjCorporateName: document.getElementById('cnpj_corporate_name'),
    cnpjOpeningDate: document.getElementById('cnpj_opening_date')
  };

  const setDisplayAndAttributes = (element, display, required) => {
    element.style.display = display;
    element.querySelectorAll('input').forEach(input => {
      required ? input.setAttribute('required', true) : input.removeAttribute('required');
    });
  };

  const updateFields = () => {
    if (elements.rgRadio.checked) {
      setDisplayAndAttributes(elements.rgFields, 'block', true);
      setDisplayAndAttributes(elements.cnhFields, 'none', false);
    } else if (elements.cnhRadio.checked) {
      setDisplayAndAttributes(elements.cnhFields, 'block', true);
      setDisplayAndAttributes(elements.rgFields, 'none', false);
    }
  };

  const applyMasks = () => {
    $('[data-mask-cpf]').mask('000.000.000-00');
    $('[data-mask-zipcode]').mask('00000-000');
    $('[data-mask-phone]').mask('(00) 00000-0000');
  };

  const fillElementValue = (elementId, value, errorMessage) => {
    const el = document.getElementById(elementId);
    if (el) {
      el.value = value || '';
    } else {
      console.error(`Elemento ${errorMessage} não encontrado.`);
    }
  };

  const fetchCNPJData = (lockerId) => {
    fetch(`/panel/lockers/${lockerId}/cnpj`)
      .then(response => {
        if (!response.ok) throw new Error('Erro ao buscar informações do CNPJ.');
        return response.json();
      })
      .then(data => {
        fillElementValue('representative_name', data.name_franchisee, 'representative_name');
        fillElementValue('billing_address_street', data.logradouro, 'billing_address_street');
        fillElementValue('billing_address_number', data.numero, 'billing_address_number');
        fillElementValue('billing_address_complement', data.complemento, 'billing_address_complement');
        fillElementValue('billing_address_neighborhood', data.bairro, 'billing_address_neighborhood');
        fillElementValue('billing_address_zipcode', data.cep, 'billing_address_zipcode');
        fillElementValue('cnpj_corporate_name', data.corporate_name, 'cnpj_corporate_name');
        fillElementValue('cnpj_opening_date', data.opening_date, 'cnpj_opening_date');
        fillElementValue('city_id', data.city_id, 'city_id');
      })
      .catch(error => {
        console.error('Erro na requisição:', error);
        alert('Erro ao buscar informações do CNPJ.');
      })
      .finally(() => {
        elements.fetchCNPJBtn.innerText = 'Buscar CNPJ';
        elements.fetchCNPJBtn.disabled = false;
      });
  };

  const handleFetchCNPJClick = () => {
    const lockerSelect = document.getElementById('lockerSelect');
    const lockerId = lockerSelect ? lockerSelect.value : null;

    if (!lockerId) {
      alert('Por favor, selecione um armário.');
      return;
    }

    elements.fetchCNPJBtn.innerText = 'Carregando...';
    elements.fetchCNPJBtn.disabled = true;
    fetchCNPJData(lockerId);
  };

  const validateForm = (event) => {
    if (elements.cnpjCorporateName.value.trim() === '' || elements.cnpjOpeningDate.value.trim() === '') {
      event.preventDefault();
      alert('Por favor, preencha os campos de Razão Social e Data de Abertura.');
    }
  };

  updateFields();
  elements.rgRadio.addEventListener('change', updateFields);
  elements.cnhRadio.addEventListener('change', updateFields);

  $(document).ready(applyMasks);

  if (elements.fetchCNPJBtn) {
    elements.fetchCNPJBtn.addEventListener('click', handleFetchCNPJClick);
  } else {
    console.error('O botão fetchCNPJBtn não foi encontrado.');
  }

  if (elements.form) {
    elements.form.addEventListener('submit', validateForm);
  }
});
