
require("@nathanvda/cocoon")
import $ from 'jquery';
import "bootstrap";

$(() => {
  $('#form-execute-door-modal').submit(function (event) {
    console.log('Form está sendo enviado para:', $(this).attr('action'));
    let formExecuteDoorError = false;
    $('#customer-errors-explanation').html('');
    $('#justification-errors-explanation').html('');

    if ($('#customer').val() == '') {
      let errorMessage = $('#customer').data('error-message');
      $('#customer-errors-explanation').html(errorMessage);
      formExecuteDoorError = true;
    }

    if ($('#justification').val() == '') {
      let errorMessage = $('#justification').data('error-message');
      $('#justification-errors-explanation').html(errorMessage);
      formExecuteDoorError = true;
    }

    if (formExecuteDoorError) {
      return false; // Impede o envio do formulário
    } else {
      $('#execute-door-modal').modal('hide');
    }
  });

  $('#execute-door-modal').on('hidden.bs.modal', function (e) {
    console.log('entrou aqui 2');
    $('#customer-errors-explanation').html('');
    $('#customer').val('');
    $('#justification-errors-explanation').html('');
    $('#justification').val('');
  });

  $('#execute-door-modal').on('show.bs.modal', function (event) {
    let button = $(event.relatedTarget); // Button that triggered the modal
    console.log('entrou aqui s', button.data('action'))
    let doorCode = button.data('door-code'); // Extract info from data-* attributes
    let action = button.data('action'); // Extract info from data-* attributes
    console.log('entrou aqui', action); // Verifique se o action está correto
    let modal = $(this);
    modal.find('.modal-title').text('Liberar máquina código ' + doorCode);
    modal.find('#form-execute-door-modal').attr('action', action);
  });
  //Date picker
  $('#panel_locker_franchise_agreement_due_date').datepicker({
    autoclose: true,
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
  });

  //Date picker
  $('#panel_locker_property_lease_agreement_due_date').datepicker({
    autoclose: true,
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
  });

  //Date picker
  $('#panel_locker_insurance_policy_due_date').datepicker({
    autoclose: true,
    language: 'pt-BR',
    format: 'dd/mm/yyyy',
  });

  $("#lockers-table").DataTable({
    "dom": '<"d-flex flex-row"l><"ml-auto"f>rt<"d-flex flex-row"i><"ml-auto"p>',
    "columnDefs": [{
      "targets": [3, 4],
      "orderable": false
    }],
    language: {
      url:
        'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Portuguese-Brasil.json'
    },
  });

  
});
